.cookie-consent {
    position: absolute;
    bottom: 20;
    height: 10rem;
    left: 30px;
    width: 100;
    /* right: 0; */
    background-color: rgb(147, 173, 199);
    border-top: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    border: 2px solid #e97730;
    /* display: flex; */
    /* display: grid; */
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
}

.cookie-consent__message {
    top: 20px;
    flex: 2;
}

.cookie-consent__actions {
    display: flex;
    gap: 8px;
}

.cookie-consent__button {
    /* padding: 10px 30px; */
    
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 10px;
    height: 40px;
    width: 110px;
}

.cookie-consent__button1 {
    /* padding: 10px 30px; */
    border: none;
    cursor: pointer;
    border-radius: 4px;
    height: 40px;
    width: 130px;
    margin-left: 10rem;
}

.cookie-consent__button.accept {
    background-color: #0053f0;
    color: white;
}

.cookie-consent__button:hover {
    opacity: 0.8;
}

.cookie-consent__close {
    position: fixed;
    background-color: #e97730;
    border-radius: 4px;
    border: none;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    color: white;
    width: 20px;
    height: 25px;
    justify-items: center;
}


.cookie-footer {
    position: fixed;
    bottom: 0;
    left: 1;
    right: 2%;
    color: white;
    /* padding: 10px 20px; */
    /* text-align: center; */
}

/* .cookie-footer button {
    margin: 10px;
    background-color: white;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
} */

.cookie-footer button:hover {
    background-color: #e97730;
    /* Button hover color */
}

.loader-container {
  position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    z-index: 999999999999999999999999 !important;
  }
  
  .loader-image {
    height: 128px; /* Initial height */
    animation: slideAnimation 2s infinite alternate; /* Animation properties */
  }
  
  @keyframes slideAnimation {
    from {
      transform: translateX(0); /* Starting position */
    }
    to {
      transform: translateX(calc(100% - 200px)); /* Ending position */
    }
  }

  /* .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    backdrop-filter: blur(5px); 
    display: flex;
    justify-content: center; 
    align-items: center; 
    z-index: 999999999999999999999999 !important;
  } */


  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(240 248 255 / 50%);
    /* backdrop-filter: blur(5px); */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999999999999999999999 !important;
}

  .slider-bg {
    position: fixed;
    left: 0px;
    right: 0px;
    z-index: 9999999999;
    background: #eeeeeebf;
    width: 100%;
    height: 100%;
    top: 0px;
}

  .slider {
    position: relative;
    z-index: 99999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(2943px / 9);
    height: 209px;
    /* background-image: url(/images/loader.png); */
    animation: animate 1s steps(9) infinite;
}

.countdown {
  font-weight: bold;
  color: #035189;
  margin-top: -125px;
  top: 10;
  margin: 54px 20px 7px 832px;
  font-size: 49px;
}



/* HTML: <div class="loader"></div> */
.loader-1 {
  width: 40px;
  aspect-ratio: 1;
  border-radius: 50%;
  /* background: #7e7e7e; */
    background: #07528B;
  clip-path: polygon(0 0,100% 0,100% 100%,0 100%);
  animation: l1 2s infinite cubic-bezier(0.3,1,0,1);
}
@keyframes l1 {
  33% {border-radius: 0;background: #e97730 ;clip-path: polygon(0 0,100% 0,100% 100%,0 100%)}
  66% {border-radius: 0;background: #07528B ;clip-path: polygon(50% 0,50% 0,100% 100%,0 100%)}
}

/* HTML: <div class="loader"></div> */
.loader-2 {
  width: 40px;
  aspect-ratio: 1;
  background: #e97730;
  clip-path: polygon(0 0,100% 0,100% 100%);
  animation: l2 2s infinite cubic-bezier(0.3,1,0,1);
}
@keyframes l2 {
  25%  {clip-path: polygon(0    0,100% 0   ,0 100%)}
  50%  {clip-path: polygon(0    0,100% 100%,0 100%)}
  75%  {clip-path: polygon(100% 0,100% 100%,0 100%)}
  100% {clip-path: polygon(100% 0,100% 100%,0 0   )}
}

/* HTML: <div class="loader"></div> */
.loader-3 {
  width: 50px;
  height: 50px;
  display: flex;
  transform: rotate(45deg);
  animation: l3-0 1.5s infinite linear;
}
.loader-3:before,
.loader-3:after {
  content: "";
  width: 50%;
  background: #07528B;
  clip-path: polygon(0 50%,100% 0,100% 100%);
  animation: inherit;
  animation-name: l3-1;
}
.loader-3:after {
  clip-path: polygon(0 0,100% 50%,0% 100%);
  animation-name: l3-2;
}
@keyframes l3-0 {
  25% {width:50px;height:50px;transform:rotate(0)}
  50% {width:50px;height:50px}
  75% {width:70.70px;height:35.35px}
  100%{width:70.70px;height:35.35px;transform:rotate(0)}
}
@keyframes l3-1 {
  0%,25% {clip-path: polygon(0 50% ,100% 0,100% 100%);transform:translate(0.3px)}
  50%    {clip-path: polygon(0 50% ,100% 0,100% 100%);transform:translate(-5px)}
  75%    {clip-path: polygon(0 100%,0    0,100% 100%);transform:translate(-5px)}
  100%   {clip-path: polygon(0 100%,0    0,100% 100%);transform:translate(17.7px)}
}
@keyframes l3-2 {
  0%,25% {clip-path: polygon(0 0,100% 50%,0    100%);transform:translate(-0.3px) }
  50%    {clip-path: polygon(0 0,100% 50%,0    100%);transform:translate(5px) }
  75%    {clip-path: polygon(0 0,100% 0  ,100% 100%);transform:translate(5px)}
  100%   {clip-path: polygon(0 0,100% 0  ,100% 100%);transform:translate(-17.7px) }
}

/* HTML: <div class="loader"></div> */
.loader-4 {
  width: 40px;
  height: 40px;
  color: #e97730;
  background:
    conic-gradient(from  -45deg at top    20px left 50% ,#0000 ,currentColor 1deg 90deg,#0000 91deg),
    conic-gradient(from   45deg at right  20px top  50% ,#0000 ,currentColor 1deg 90deg,#0000 91deg),
    conic-gradient(from  135deg at bottom 20px left 50% ,#0000 ,currentColor 1deg 90deg,#0000 91deg),
    conic-gradient(from -135deg at left   20px top  50% ,#0000 ,currentColor 1deg 90deg,#0000 91deg);
  animation: l4 1.5s infinite cubic-bezier(0.3,1,0,1);
}
@keyframes l4 {
   50%  {width:60px;height: 60px;transform: rotate(180deg)}
   100% {transform: rotate(360deg)}
}


/* HTML: <div class="loader"></div> */
.loader-5 {
  width: 40px;
  height: 40px;
  --c:no-repeat linear-gradient(#07528B 0 0);
  background: var(--c),var(--c),var(--c),var(--c);
  background-size: 21px 21px;
  animation: l5 1.5s infinite cubic-bezier(0.3,1,0,1);
}
@keyframes l5 {
 0%   {background-position: 0    0,100% 0   ,100% 100%,0 100%}
 33%  {background-position: 0    0,100% 0   ,100% 100%,0 100%;width:60px;height: 60px}
 66%  {background-position: 100% 0,100% 100%,0    100%,0 0   ;width:60px;height: 60px}
 100% {background-position: 100% 0,100% 100%,0    100%,0 0   }
}


/* HTML: <div class="loader"></div> */
.loader-6 {
  width: 40px;
  height: 40px;
  display: grid;
  animation: l6-0 1.5s infinite linear;
}
.loader-6:before,
.loader-6:after {
  content: "";
  grid-area: 1/1;
  background: #e97730;
  animation: 
    l6-1 1.5s infinite linear,
    l6-2 1.5s infinite linear;
}
.loader-6:after {
  --s:-1;
  animation-direction: reverse;
}
@keyframes l6-0 {
  0%,9%,91%,100% {background: #07528B}
  10%,90%        {background: #0000}
}
@keyframes l6-1 {
  0%,33%   {clip-path: polygon(0   0,50% 100%,100% 0,100% 100%,0 100%)}
  66%,100% {clip-path: polygon(50% 0,50% 100%,50%  0,100% 100%,0 100%)}
}
@keyframes l6-2 {
  0%,10%,90%,100% {transform: scale(var(--s,1)) translateY(0)}
  33%,66%         {transform: scale(var(--s,1)) translateY(50%)}
}


/* HTML: <div class="loader"></div> */
.loader-7 {
  width: 40px;
  aspect-ratio: 1;
  color: #e97730;
  position: relative;
  background:
    conic-gradient(from 134deg at top   ,currentColor 92deg,#0000 0) top,
    conic-gradient(from -46deg at bottom,currentColor 92deg,#0000 0) bottom;
  background-size: 100% 50%;
  background-repeat: no-repeat;
}
.loader-7:before {
  content:'';
  position: absolute;
  inset: 0;
  --g:currentColor 14.5px,#0000 0 calc(100% - 14.5px),currentColor 0;
  background:
    linear-gradient( 45deg,var(--g)),
    linear-gradient(-45deg,var(--g));
   animation: l7 1.5s infinite cubic-bezier(0.3,1,0,1);
}
@keyframes l7 {
   33%  {inset:-10px;transform: rotate(0deg)}
   66%  {inset:-10px;transform: rotate(90deg)}
   100% {inset:0    ;transform: rotate(90deg)}
}

/* HTML: <div class="loader"></div> */
.loader-8 {
  width: 40px;
  aspect-ratio: 1;
  display: grid;
  animation: l8-0 1.5s infinite linear;
}
.loader-8:before,
.loader-8:after {
  content: "";
  grid-area: 1/1;
  background: #07528B;
  clip-path: polygon(0 0%,100% 0,100% 100%);
  animation: inherit;
  animation-name: l8-1;
}
.loader-8:after {
  --s:-1;
}
@keyframes l8-0 {
  66%      {transform: skewX(0deg)}
  80%,100% {transform: skewX(-45deg)}
}
@keyframes l8-1 {
  0%   {transform:scale(var(--s,1)) translate(-0.5px,0)}
  33%  {transform:scale(var(--s,1)) translate(calc(1px - 50%),calc(1px - 50%))}
  66%  {transform:scale(var(--s,1)) translate(calc(1px - 50%),0%)}
  100% {transform:scale(var(--s,1)) translate(calc(0.5px - 50%),0%)}
}


/* HTML: <div class="loader"></div> */
.loader-9 {
  width: 40px;
  height: 20px;
  background: #e97730;
  position: relative;
  animation: l9-0 1.5s infinite linear;
}
.loader-9:before,
.loader-9:after {
   content:"";
   position: absolute;
   background: inherit; 
   bottom: 100%;
   width: 50%;
   height: 100%;
   animation: inherit;
   animation-name: l9-1;
}
.loader-9:before {
  left: 0;
  transform-origin: bottom left;
  --s:-1;
}
.loader-9:after {
  right: 0;
  transform-origin: bottom right;
}
@keyframes l9-0 {
   0%,10%   {transform:translateY(0%)    scaleY(1)}
   49.99%   {transform:translateY(-50%)  scaleY(1)}
   50%      {transform:translateY(-50%)  scaleY(-1)}
   90%,100% {transform:translateY(-100%) scaleY(-1)}
}
@keyframes l9-1 {
   10%,90% {transform: rotate(0deg)}
   50%     {transform: rotate(calc(var(--s,1)*180deg))}
}


/* HTML: <div class="loader"></div> */
.loader-10 {
  display: flex;
  width: 50px;
  aspect-ratio: 1;
  animation: l10-0 1.5s infinite linear;
}
.loader-10:before,
.loader-10:after {
  content: "";
  width: 50%;
  background: #07528B;
  clip-path: polygon(0 0,100% 50%,0% 100%);
  animation: inherit;
  animation-name: l10-1;
  transform-origin: bottom left;
}
.loader-10:before {
  clip-path: polygon(0 50%,100% 0,100% 100%);
  transform-origin: bottom right;
  --s:-1;
}
@keyframes l10-0 {
  0%,34.99% {transform: scaley(1)}
  35%,70%   {transform: scaley(-1)}
  90%,100%  {transform: scaley(-1) rotate(180deg)}
}

@keyframes l10-1 {
  0%,10%,70%,100%{transform:translateY(-100%) rotate(calc(var(--s,1)*135deg))}
  35%            {transform:translateY(0%)    rotate(0deg)}
}



/* HTML: <div class="loader"></div> */
.loader-40 {
  width: 50px;
  aspect-ratio: 1;
  color: #e97730;
  position: relative;
  animation: l40 .5s infinite alternate;
}
.loader-40:before,
.loader-40:after {
  content: "";
  position: absolute;
  inset: 0;
  background-size: 25px 25px;
  background-position: 0 0,100% 0,100% 100%,0 100%;
  background-repeat: no-repeat;
}
.loader-40:before {
  background-image:
    radial-gradient(farthest-side at top    left ,currentColor 96%,#0000),
    radial-gradient(farthest-side at top    right,currentColor 96%,#0000),
    radial-gradient(farthest-side at bottom right,currentColor 96%,#0000),
    radial-gradient(farthest-side at bottom left ,currentColor 96%,#0000);
  animation: l40-1 1s infinite;
}
.loader-40:after {
  background-image: 
    radial-gradient(farthest-side at top    left ,#0000 94%,currentColor 96%),
    radial-gradient(farthest-side at top    right,#0000 94%,currentColor 96%),
    radial-gradient(farthest-side at bottom right,#0000 94%,currentColor 96%),
    radial-gradient(farthest-side at bottom left ,#0000 94%,currentColor 96%);
  animation: l40-2 1s infinite;
}
@keyframes l40-1 {
  0%,10%,90%,100% {inset:0}
  40%,60% {inset:-10px}
}
@keyframes l40-2 {
  0%,40%  {transform: rotate(0)}
  60%,100%{transform: rotate(90deg)}
}


/* HTML: <div class="loader"></div> */
.loader-39 {
  width: 50px;
  aspect-ratio: 1;
  color: #07528B;
  background: currentColor;
  border-radius: 50%;
  position: relative;
}
.loader-39:before {
  content: "";
  position: absolute;
  background:
    radial-gradient(farthest-side at bottom right,#0000 94%,currentColor 96%) 0    0   ,
    radial-gradient(farthest-side at bottom left ,#0000 94%,currentColor 96%) 100% 0   ,
    radial-gradient(farthest-side at top    left ,#0000 94%,currentColor 96%) 100% 100%,
    radial-gradient(farthest-side at top    right,#0000 94%,currentColor 96%) 0    100%;
  background-size: 25px 25px;
  background-repeat: no-repeat;
  animation: 
    l39-1 1s infinite,
    l39-2 1s infinite;
}
@keyframes l39-1 {
  0%,10%,90%,100% {inset:0}
  40%,60% {inset:-10px}
}

@keyframes l39-2 {
  0%,40%  {transform: rotate(0)}
  60%,100%{transform: rotate(90deg)}
}


/* HTML: <div class="loader"></div> */
.loader-38 {
  width: 60px;
  aspect-ratio: 1.154;
  color: #e97730;
  display: grid;
  animation: l38-0 6s infinite linear;
}
.loader-38:before,
.loader-38:after {
  content: "";
  grid-area: 1/1;
}
.loader-38:before {
  clip-path: polygon(25% 0,75% 0,100% 50%,75% 100%,25% 100%,0 50%);
  background: conic-gradient(from -30deg,#0000 60deg,currentColor 0);
}
.loader-38:after {
  background: conic-gradient(from -30deg,currentColor 60deg,#0000 0);
  animation: l38 0.5s infinite alternate;
}
@keyframes l38-0 {
  0%,16.66%     {transform: rotate(0deg)}
  16.67%,33.33% {transform: rotate(60deg)}
  33.34%,50%    {transform: rotate(120deg)}
  50.1%,66.66%  {transform: rotate(180deg)}
  66.67%,83.33% {transform: rotate(240deg)}
  83.34%,100%   {transform: rotate(300deg)}
}
@keyframes l38 {
  100%  {transform: translateY(-20px)}
}


/* HTML: <div class="loader"></div> */
.loader-37 {
  width: 60px;
  aspect-ratio: 1.154;
  --c: #0000 ,#07528B 2deg 59deg,#0000 61deg;
  --c1:conic-gradient(from 149deg at top   ,var(--c));
  --c2:conic-gradient(from -31deg at bottom,var(--c));
  background:
    var(--c1) top,
    var(--c1) bottom right,
    var(--c2) bottom,
    var(--c1) bottom left;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  animation: l37 1s infinite;
}
@keyframes l37 {
  80%,100%{background-position: bottom right,bottom left,bottom,top}
}

/* HTML: <div class="loader"></div> */
.loader-36 {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
}
.loader-36:before,
.loader-36:after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: #e97730;
  -webkit-mask:repeating-linear-gradient(#000 0 10px,#0000 0 20px);
}
.loader-36:after {
  -webkit-mask:repeating-linear-gradient(#0000 0 10px,#000 0 20px);
  animation: l36 1.5s infinite;
}
@keyframes l36 {
  0%,
  10%  {transform: rotate(0)      translate(0)}
  35%  {transform: rotate(0)      translate(100%)}
  66%  {transform: rotate(180deg) translate(100%)}
  90%,
  100% {transform: rotate(180deg) translate(0) }
}


/* HTML: <div class="loader"></div> */
.loader-35 {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
}
.loader-35:before,
.loader-35:after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: #07528B;
  -webkit-mask:repeating-linear-gradient(#000 0 5px,#0000 0 10px);
  animation: l35 1.5s infinite;
}
.loader-35:after {
  -webkit-mask:repeating-linear-gradient(#0000 0 5px,#000 0 10px);
  --s:-1;
}
@keyframes l35 {
  0%,
  10% {transform: translate(0) rotate(0)}
  35% {transform: translate(calc(var(--s,1)*50%)) rotate(0)}
  66% {transform: translate(calc(var(--s,1)*50%)) rotate(calc(var(--s,1)*180deg))}
  90%,
  100% {transform: translate(0) rotate(calc(var(--s,1)*180deg))}
}


/* HTML: <div class="loader"></div> */
.loader-34 {
  height: 60px;
  aspect-ratio: 1;
  --c:no-repeat linear-gradient(#e97730 0 0);
  background: 
    var(--c),var(--c),
    var(--c),var(--c),
    var(--c),var(--c);
  animation: 
    l34-1 2s infinite alternate,
    l34-2 2s infinite alternate;
}
@keyframes l34-1 {
  0%,20%   {background-size:20px 20px}
  40%,60%  {background-size:100% 20px}
  80%,100% {background-size:20px 20px}
}
@keyframes l34-2 {
  0%,40%  {background-position: 0   0,100% 0, 50% 50%, 50% 50%, 0   100%,100% 100%}
  60%,100%{background-position: 50% 0,50%  0, 0   50%,100% 50%, 50% 100%,50%  100%}
}

/* HTML: <div class="loader"></div> */
.loader-33 {
  height: 45px;
  --c:no-repeat linear-gradient(#07528B 0 0);
  background: 
    var(--c) left,
    var(--c) center,
    var(--c) right;
  background-size: 16px 100%;
  animation: 
    l33-1 1.5s infinite,
    l33-2 1.5s infinite;
}
@keyframes l33-1 {
  0%,100% {width:45px}
  35%,65% {width:65px}
}
@keyframes l33-2 {
  0%,40%  {transform: rotate(0) }
  60%,100%{transform: rotate(90deg) }
}

/* HTML: <div class="loader"></div> */
.loader-32 {
  --c:no-repeat linear-gradient(#e97730 0 0);
  background: 
    var(--c),var(--c),var(--c),
    var(--c),var(--c),var(--c),
    var(--c),var(--c),var(--c);
  background-size: 16px 16px;
  animation: 
    l32-1 1s infinite,
    l32-2 1s infinite;
}
@keyframes l32-1 {
  0%,100% {width:45px;height: 45px}
  35%,65% {width:65px;height: 65px}
}
@keyframes l32-2 {
  0%,40%  {background-position: 0 0,0 50%, 0 100%,50% 100%,100% 100%,100% 50%,100% 0,50% 0,  50% 50% }
  60%,100%{background-position: 0 50%, 0 100%,50% 100%,100% 100%,100% 50%,100% 0,50% 0,0 0,  50% 50% }
}

/* HTML: <div class="loader"></div> */
.loader-31 {
  --c:no-repeat linear-gradient(#e97730 0 0);
  background: 
    var(--c) 0 0,
    var(--c) 0 50%,
    var(--c) 0 100%,
    var(--c) 50% 0,
    var(--c) 50% 50%,
    var(--c) 50% 100%,
    var(--c) 100% 0,
    var(--c) 100% 50%,
    var(--c) 100% 100%;
  background-size: 16px 16px;
  animation: l31 .5s infinite alternate;
}
@keyframes l31 {
  0%,20%  {width:45px;height: 45px}
  90%,100%{width:65px;height: 65px}
}


/* ORANGE: #e97730 */
/* BLUE: #07528B */
/* #7e7e7e */
/* ToggleSwitch.css */
.switch {
    position: relative;
    width: 80px;
    height: 40px;
    border-radius: 20px;
    background-color: grey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0 5px;
    transition: background-color 0.3s;
  }
  
  .switch.on {
    background-color: green;
  }
  
  .toggle {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
    transition: left 0.3s;
  }
  
  .switch.on .toggle {
    left: 45px;
  }
  
  .labels {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: white;
    font-weight: bold;
  }
  
  .switch.on .labels span:first-child {
    color: white;
  }
  
  .switch.off .labels span:last-child {
    color: grey;
  }
  
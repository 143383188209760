/* styles.css */
.features-page {
    display: flex;
    justify-content: space-around;
    padding: 2rem;
    margin-bottom: 10rem;
  }
  
  .features-section,
  .solutions-section {
    flex: 1;
    padding: 1rem;
  }
  
  .features-list,
  .solutions-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .feature,
  .solution {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .feature-icon,
  .solution-icon {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  
  .feature-title,
  .solution-title {
    margin: 0;
    font-size: 1.2rem;
  }
  
  .solution-description {
    text-align: center;
    font-size: 0.9rem;
  }

/* // */
.outer-border {
    align-content: center;
    height: 90px;
    width: 90px;
    border: 2px solid #E97730;
    border-radius: 50%;
    margin: auto;
    background-color: transparent;
    margin-top: -21%;
    padding: 0;
    position: relative;
    overflow: hidden;
  }
  
  .inner-border {
    height: 80px;
    width: 80px;
    padding: 10px;
    border: 2px solid #E97730;
    border-radius: 50%;
    margin: auto;
    background-color: #E97730;
    position: relative;
    transition: background-color 0.3s ease;
  }
  
  .image-background {
    height: 100%;
    width: 100%;
    
  }
  
  .solution-card:hover {
    background-color: #FFD1B0;
  }
  .solution-card {
    height: 30%;
    background-image: "./images/solution-box-bg.svg";
    background-size: cover;
    background-position: center;
    background-color: #f5f5f500;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
    justify-content: flex-start;
    
    flex-wrap: wrap;
    padding: 3rem;
    margin-top: 5rem;
    
    /* display: flex; */
    /* align-items: flex-start; */
    /* align-content: flex-start; */
    /* display: flex;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }
  
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    background-color: #ff6b35;
    border-radius: 50%;
    margin-right: 16px;
    margin-top: -10%;
    margin-left: 45%;
    /* display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    background-color: #ff6b35;
    border-radius: 50%;
    margin-right: 16px; */
  }
  
  .icon {
    font-size: 32px;
    color: #fff;
  }
  
  .content {
    flex: 1;
  }
  
  .title {
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 500;
    margin-top: -2rem;
  }
  
  .description {    
    color: #4d4c4c;
    line-height: 1.4;
    font-size: 14px;
    /* line-height: 17px; */
    font-weight: 400;
  }

  @media (max-width: 576px) {

    .title {
      /* padding: 10px; */
      text-transform: capitalize;
      font-size: 13px;
      font-weight: 500;
      text-align: left;
      margin-top: -30rem;
    }
    
    .description {    
      color: #4d4c4c;
      line-height: 1.4;
      font-size: 12px;
      /* line-height: 17px; */
      font-weight: 400;
      margin-left: 1rem;
    }
    .solution-card {
      height: 12rem;
      background-image: "./images/solution-box-bg.png";
      background-size: cover;
      background-position: center;
      background-color: #f5f5f500;
      border-radius: 8px;
      /* padding: 13px; */
      box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
      justify-content: flex-start;
      flex-wrap: wrap;
      /* padding: 3rem; */
      margin-top: 0rem;
      margin-left: 13%;
      margin-right: 3%;
      margin-bottom: 1rem;
      /* display: flex; */
      /* align-items: flex-start; */
      /* align-items: flex-start; */
      /* align-content: flex-start; */
  }
    
  }
/* HomeSection.css */

.section {
    padding: 40px 0;
  }
  
  .section-padding {
    padding: 0 15px;
  }
  
  .home3-main {
    margin-bottom: 20px;
    display: inline-block;
    float: unset;
    width: 100%;
    /* display: flex;
    flex-wrap: wrap;
    justify-content: center; */
  }
  
  .listing {
    
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* align-content: space-evenly; */

  }
  
  .list-item {
    padding: 10px;
  }
  
  .in-border {
    border: 2px solid #E97730;
    border-radius: 10px;
    padding: 20px;
    /* width: 20rem; */
    height: 4rem;
  }
  
  .in-border a {
    display: block;
  }
  
  .in-border img {
    max-width: 100%;
    height: auto;
  }
  
  .content {
    text-align: center;
    margin-top: 4px;
  }
  
  .heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .subtitle {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .common-btn {
    display: flex;
    justify-content: center;
  }
  
  .btn {
    position: relative;
    margin: 0 10px;
  }
  
  .btn a {
    display: inline-block;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
  }
  
  .btn img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .btn:hover img.hover-img {
    opacity: 1;
  }
  
  /* Responsive styles */
  @media (max-width: 767px) {
    .list-item {
      width: 50%;
    }
  }
  
  @media (max-width: 575px) {
    /* HomeSection.css */

.section {
  padding: 40px 0;
}

.section-padding {
  padding: 0 15px;
}

.home3-main {
  margin-bottom: 20px;
  display: inline-block;
  float: unset;
  width: 100%;
  /* display: flex;
  flex-wrap: wrap;
  justify-content: center; */
}

.listing {
  
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* align-content: space-evenly; */
  

  
}

.list-item {
  padding: 10px;
}

.in-border {
  border: 2px solid #E97730;
  border-radius: 30px;
  padding: 0px;
  width: auto;
  height: 60px;
}

.in-border a {
  display: block;
}

.in-border img {
  max-width: 100%;
  height: auto;
}

.content {
  text-align: center;
  margin-top: 40px;
}

.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 18px;
  margin-bottom: 20px;
}

.common-btn {
  display: flex;
  justify-content: center;
}

.btn {
  position: relative;
  margin: 0 10px;
}

.btn a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
}

.btn img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.btn:hover img.hover-img {
  opacity: 1;
}

    .list-item {
      width: 100%;
    }
  }
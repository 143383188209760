.register-main .bg-img {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}
@media (min-width: 1920px) and (max-width: 2559px) {
  .register-main .input-field input {
    padding: 5px 20px;
  }
}
.register-main .input-field img {
  max-height: 40px;
  max-width: 40px;
  margin-right: 10px;
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .register-main .input-field img {
    max-height: 40px;
    max-width: 40px;
    margin-right: 10px;
  }
}

.register-main .input-field {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  font-size: 14px;
  border: 2px solid #fff;
  border-radius: 30px;
  margin-bottom: 20px;
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .register-main .input-field {
    padding: 15px 30px;
    font-size: 18px;
  }
}
.register-main .btm.mob {
  display: none;
}
.register-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  margin: 40px auto;
  padding: 30px 25px;
  position: relative;
  background: #07528b;
  border-radius: 12px;
  flex-wrap: wrap;
}

.see-full {
  width: 100%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .register-main .or {
    font-size: 20px;
  }
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .register-main form span {
    font-size: 20px;
  }
}
@media (min-width: 1920px) and (max-width: 2559px) {
  .register-main form span {
    margin-top: 15px;
  }
}

.register-main .btm span + a {
  display: unset;
  background: unset;
  padding: 0 0 0 3px;
  color: #fff;
  text-decoration: underline;
  text-transform: capitalize;
  font-weight: 500;
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .register-main .btm span + a {
    padding: 0 0 0 10px;
    font-size: 20px;
  }
}

.register-main form span {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  display: block;
  padding: 0 10px;
  margin-top: 10px;
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .register-main .check-btm {
    margin-top: 30px;
  }
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .register-main form .form-btm input {
    padding: 20px 53px;
    font-size: 20px;
  }
}

.register-main form .form-btm .bg-mg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.register-main form .form-btm input {
  border: none;
  outline: none;
  padding: 10px 26px;
  border-radius: 20px;
  color: #07528b;
  font-weight: 500;
  position: relative;
  z-index: 10;
  background: white;
  display: flex;
  align-items: center;
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .register-main .check-btm .click .checkbox-container label {
    font-size: 30px;
  }
}

.register-main .check-btm .click {
  color: #fff;
  display: flex;
  justify-content: space-between;
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .register-main .btm .google-act {
    margin-bottom: 30px;
  }
}
@media (min-width: 1920px) and (max-width: 2559px) {
  .register-main .check-btm .click a {
    font-size: 30px;
  }
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .register-main form span {
    font-size: 20px;
  }
}

.register-main .check-btm .click a {
  text-transform: capitalize;
  color: #e4e8eb;
  text-decoration: underline;
}

.register-main .btm .google-act {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  background: #fff;
  border-radius: 28px;
  margin-bottom: 8px;
  width: 20rem;
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .register-main .btm {
    padding: 0px 0;
  }
}

.register-main .or {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  padding: 10px 0;
}

.register-main .btm a span {
  padding: 0 0 0 10px;
  font-size: 14px;
  color: #4a4242;
  font-weight: 500;
  display: inline-block;
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .register-main .btm a span {
    padding: 15px 30px;
    font-size: 20px;
  }
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .register-main .or {
    font-size: 20px;
  }
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .register-main .btm span {
    font-size: 20px;
    padding: 1px 0 0;
  }
}

.register-main .btm span {
  color: #fff;
  display: inline-block;
  /* font-size: 14px; */
  padding: 3px 0 0;
}



.register-main .check-btm .click .checkbox-container div.check {
  position: absolute;
  display: block;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  border: 1px solid #fff;
  margin-top: 0;
  border-radius: 2px;
}

.form-control input::placeholder {
  color: #fff;
}
.input-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  border: 2px solid white;
  border-radius: 25px;
  padding: 5px;
}
.input-signup {
  color: white;
  border: none;
  outline: none;
  background: transparent;
  flex: 1;
  padding: 5px;
  font-size: 24px;
}

.input-signup::placeholder{
  color: rgba(255, 255, 255, 0.703) !important;
}
.imgIcon-signup {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-left: 5px;
}

@media (max-width: 576px) {
  .form-control input::placeholder {
    color: #fff;
  }
  .input-signup::placeholder{
    color: rgba(255, 255, 255, 0.703) !important;
  }
  .input-container {
    margin-top: 15px;
    display: flex;
    align-items: center;
    border: 2px solid white;
    border-radius: 25px;
    padding: 5px;
  }
  .input-signup {
    color: white;
    border: none;
    outline: none;
    background: transparent;
    flex: 1;
    padding: 5px;
  }

  .imgIcon-signup {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }

  /* .register-main .input-field input {
          padding: 5px 20px;
      }
    
    .register-main .input-field img {
      max-height: 40px;
      max-width: 40px;
      margin-right: 10px;
    }
    
    
    .register-main .input-field img {
          max-height: 40px;
          max-width: 40px;
          margin-right: 10px;
      } */

  .check-btm .click input[type="checkbox"] {
    margin-right: 10px;
    position: relative;
    z-index: 2;
  }

  .check-btm .click .checkbox-container div.check {
    position: absolute;
    display: block;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    border: 1px solid #fff;
    margin-top: 0;
    border-radius: 2px;
  }

  .or {
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    padding: 10px 0;
  }
  .register-main .check-btm .click input[type="checkbox"] {
    margin-right: 10px;
    position: relative;
    z-index: 2;
  }

  .register-main .check-btm .click .checkbox-container div.check {
    position: absolute;
    display: block;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    border: 1px solid #fff;
    margin-top: 0;
    border-radius: 2px;
  }
}

@media (max-width: 992px) {
  .register-main .btm.mob {
    display: table;
    padding: 10px 0;
  }
}
.register-main .btm.mob {
  display: none;
}

@media (max-width: 992px) {
  .register-main .btm {
    margin-top: 10px;
  }
}

.register-main .btm {
  z-index: 10;
  text-align: center;
  display: table;
  margin: auto;
  position: relative;
}

.google-sign-up-button {
  /* display: flex;
  align-items: center;
  background-color: white;
  color: black;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer; */

  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-items: center;
  border: 2px solid white;
  border-radius: 25px;
  margin-left: 5px;
  margin-right: 10px;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.google-logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.billing-table-cont {
    margin-top: 30px;
    margin: 15px 0px;
}
.billing-search {
    position: relative;
    background-color: #e97730;
    padding: 18px;
    border-radius: 20px;
    z-index: 999;
    border-radius: 25px;
}

.bg-imagee {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    font-size: 24px;
}
.search-form {
    display: flex;
    position: relative;
    z-index: 1;
    font-size: 24px;
}

.search-form .form-date-field, .search-form .form-select-field {
    position: relative;
    width: 300px;
    max-width: 27%;
}

.button-fields {
    display: flex;
    align-items: center;
    font-size: 24px;
}

.input-submit, .search-form .button-cont .export-button {
    background: transparent;
    border: none;
    font-weight: 500;
    padding: 8px 20px;
    transition: all 0.3s;
    color: #fff;
    font-size: 14px;
}

.new-btn {
    background: #679ef7 !important;
    border-radius: 50px;
    padding: 10px 30px;
    border: 1px solid #fff;
}

.input-container-notif {
    margin-top: -7px;
    display: flex;
    align-items: center;
    border: 2px solid white;
    border-radius: 25px;
    padding: 5px;
    height: 2.5rem;
    margin-right: 15px;
}


.notification-cont {
    width: 100%;
    height: 80vh;
    overflow-y: scroll;
    margin-top: 15px;
    padding-right: 20px;
}

.table-row-noti {
    margin-left: 10px;
    margin-top: 10px;
    display: flex;
    position: relative;
    padding: 8px 0px 8px 10px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.3);
    font-size: 20px;
}

.title {
    margin-top: 1px;
    color: #035189;
    font-weight: 500;
}
.bar {
    width: 5px;
    border-radius: 2px;
    background-color: #e97730;
    display: block;
    margin: 0px 10px;
}

.message {
    width: 90%;
    font-size: 16px;
}

.datetime {
    margin-left: auto;
    margin-right: 10px;
    align-self: center;
    font-size: 17px;
    color: #777;
}
.datetime-arc-mobile{
    margin-left: auto;
    margin-right: 10px;
    align-self: center;
    font-size: 14px;
    color: #777;
}

.list-btns-web {
    
    border: none;
    font-size: 17px;
    padding: 5px 15px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 10px;
}

.list-btns-mobile {
    
    border: none;
    font-size: 13px;
    padding: 5px 15px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 10px;
}
.archive-noti {
    position: relative;
    width: 18px;
    margin: 0px 30px 0px auto;
    align-self: center;
    border: none;
    background: none;
    cursor: pointer;
}



.desc {
    margin-top: 10px;
    color: #555;
    font-weight: 500;
    max-height: 28px;
    overflow-y: hidden;
    transition: all 0.5s;
}
@media (max-width: 576px) {

    .input-container-notif {
        margin-top: 12px;
        display: flex;
        align-items: center;
        border: 2px solid white;
        border-radius: 25px;
        padding: 5px;
        height: 2.5rem;
        margin-right: 0px;
    }
    .new-btn {
        margin-top: 35px;
        background: #679ef7 !important;
        border-radius: 50px;
        padding: 10px 30px;
        border: 1px solid #fff;
    }

    .new-btn-export {
        margin-top: 35px;
        background: #ffffff !important;
        border-radius: 50px;
        padding: 10px 30px;
        border: 1px solid #fff;
    }
.search-form {
    display: flex;
    position: relative;
    z-index: 1;
    font-size: 24px;
    flex-direction: column;
    align-items: center;
}

.billing-search {
    position: relative;
    background-color: #154e7a;
    padding: 40px;
    /* border-radius: 20px; */
    z-index: 999;
    border-radius: 25px;
}
}
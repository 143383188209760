
.heading-dotted-monitor::before {
    content: "...";
    position: absolute;
    bottom: -16px;
    left: 100%;
  }

  .heading-dotted-monitor {
    font-size: 34px;
    font-weight: 500;
    padding: 10px 0;
    border-bottom: 2px solid #154e7a;
    display: inline-block;
    position: relative;
    text-transform: capitalize;
    color: #545454 !important;
    margin-left: 9rem;
  }

  .configs-monitor {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* .table-data{
  display: flex;
  position: relative;
    font-size: 13px;
    margin-right: 1px;
    margin-bottom: 1px;
} */

.utilisation {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  width: 80px;
    height: 80px;
    border: 4px solid #fff;
    outline: 2px solid #e97730;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 500;
}



.percentage {
  height: 100px;
  width: 100px;
  font-size: 35px;
  width: 80px;
  height: 80px;
  border: 4px solid #fff;
  outline: 2px solid #e97730;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 500;
}

.util-title {
  position: relative;
  font-size: 15px;
  background: #e97730;
  border: 4px solid #fff;
  border-radius: 15px;
  padding: 2px 10px;
  font-weight: 500;
  font-size: 18px;
        border-radius: 50px;
}

  @media (max-width: 576px) {
    .heading-dotted-monitor {
        font-size: 24px;
        font-weight: 500;
        padding: 10px 0;
        border-bottom: 2px solid #154e7a;
        display: inline-block;
        position: relative;
        text-transform: capitalize;
        color: #154e7a;
        top: 3rem;
        margin-left: 10px;
        margin-bottom: 4rem;
    }

    .heading-dotted-monitor::before {
        content: "...";
        position: absolute;
        bottom: -12px;
        left: 100%;
    }
  }
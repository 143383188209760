

$back: white;
$color: #07528b;
$speed: 1s;

// @import url(https://fonts.googleapis.com/css?family=Lato:700);

body {
    background: $back;
    font-family: 'Lato', sans-serif;
}

.arrows {
    position: absolute;
    top: 8%;
    right: 6%;
     margin-top: 0px;
    // margin-left: -60px;
    display: inline-block;
    font-size: 26px;
    color: $color;
    text-align: center;
    width: 24rem;
    height: 60px;
    line-height: 60px;
    border: 4px solid $color;
    overflow: hidden;
    text-transform: uppercase;
    cursor: pointer;
    transition: width $speed*.5 ease-in-out,
        margin $speed*.5 ease-in-out,
        border-radius $speed*.25 ease-in-out,
        color $speed*.25 ease-in-out;

    &:hover,
    &.auto {
        width: 60px;
        margin-left: -30px;
        border-radius: 40px;
        color: rgba($color, 0);
        transition: width $speed*.5 ease-in-out,
            margin $speed*.5 ease-in-out,
            border-radius $speed.25 ease-in-out,
            color $speed*.25 ease-in-out $speed*.25;

        &:before {
            animation: lineUp $speed cubic-bezier(0.0, 0.6, 1.0, 0.4) infinite .5s;
        }

        &:after {
            animation: tipUp $speed cubic-bezier(0.0, 0.6, 1.0, 0.4) infinite .5s;
        }
    }

    &:before {
        position: absolute;
        display: inline-block;
        content: "";
        background: $color;
        width: 5px;
        height: 40px;
        top: 50%;
        left: 50%;
        margin-top: -8px;
        margin-left: -3px;
        transform: translateY(50px);
    }

    &:after {
        position: absolute;
        display: inline-block;
        content: "";
        width: 20px;
        height: 20px;
        color: $color;
        border-top: 5px solid;
        border-left: 5px solid;
        transform: rotateZ(45deg);
        top: 50%;
        left: 50%;
        margin-top: -6px;
        margin-left: -10px;
        transform: translateY(50px) rotateZ(45deg);
    }
}

@keyframes tipUp {
    0% {
        transform: translateY(50px) rotateZ(45deg);
    }

    100% {
        transform: translateY(-70px) rotateZ(45deg);
    }
}

@keyframes lineUp {
    0% {
        transform: translateY(50px);
    }

    100% {
        transform: translateY(-70px);
    }
}
.radio-label {
    margin-right: 20px;
    font-size: 18px;
    font-weight: 500;
    color: white;
  }
  
  .radio-input {
    margin-right: 8px;
    appearance: none; /* Remove default styling */
    width: 16px;
    height: 16px;
    background-color: #ccc;
    /* border: 3px solid #ccc; */
    border-radius: 50%;
    outline: none;
    cursor: pointer;
  }
  
  .radio-input:checked {
    background-color: #e97730 !important;
    border: 3px solid #ffffff;
  }
  .heading-dotted-cdn::before {
    content: "...";
    position: absolute;
    bottom: -16px;
    left: 100%;
  }

  .heading-dotted-cdn {
    font-size: 34px;
    font-weight: 500;
    padding: 10px 0;
    border-bottom: 2px solid #154e7a;
    display: inline-block;
    position: relative;
    text-transform: capitalize;
    color: #545454 !important;
    margin-left: 9rem;
  }

    .blinkStyleCdn {
      position: 'absolute';
      top: '100%';
      left: '0';
      color: #035189;
      animation: 'blinker 1s linear infinite';
    }

  .loader {
    width: 100px;
    aspect-ratio: 1;
    border-radius: 50%;
    /* background: #e20788; */
    box-shadow: 0 0 0 0 #e97730;
    animation: l1 1s infinite;
  }

    .multi-select-container {
      display: flex;
      flex-direction: row;
      margin-top: 15px;
    }
  
    .custom-radio {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  
    .custom-radio input[type="checkbox"] {
      display: none;
    }
  
    .custom-radio .radio-label {
      display: inline-block;
      padding: 10px 20px;
      background-color: white;
      color: #035189;
      border-radius: 20px;
      cursor: pointer;
      position: relative;
    }
  
    .custom-radio input[type="checkbox"]:checked+.radio-label {
      color: white;
      background-color: #e97730;
    }
  
    .custom-radio input[type="checkbox"]:before {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: white;
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
    }

  @keyframes l1 {
    100% {
      box-shadow: 0 0 0 30px #0000
    }
  }

  @media (max-width: 576px) {
    .front-mobile {
        padding: 15px;
        display: inline-block; /* Display each child element in a row */
      vertical-align: top; /*
        margin-top: 25px;
        position: absolute;
        /* width: 100%; */
        /* height: 100%; */
        backface-visibility: hidden;
       
      }
    .heading-dotted-cdn {
        font-size: 24px;
        font-weight: 500;
        padding: 10px 0;
        border-bottom: 2px solid #154e7a;
        display: inline-block;
        position: relative;
        text-transform: capitalize;
        color: #154e7a;
        top: 3rem;
        margin-left: 20px;
        margin-bottom: 4rem;
    }

    .heading-dotted-cdn::before {
        content: "...";
        position: absolute;
        bottom: -12px;
        left: 100%;
    }
  }
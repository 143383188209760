body, html {
  /* height: 100%;
  overflow: hidden;
  margin: 0; */
}

.blogs-posts-inner::-webkit-scrollbar, .latest-blogs-inner::-webkit-scrollbar {
  width: 12px;
}

.blogs-posts-inner::-webkit-scrollbar-thumb, .latest-blogs-inner::-webkit-scrollbar-thumb {
  background-color: #e97730;
  border-radius: 6px;
}

.blogs-posts-inner::-webkit-scrollbar-track, .latest-blogs-inner::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

/* Scrollbar styling for Firefox */
.blogs-posts-inner, .latest-blogs-inner {
  scrollbar-width: thin;
  scrollbar-color: #e97730 #f1f1f100;
}


:root {
  --secondary_color: #07528B;
  --primary_color: #e97730; /* Define your secondary color here */
}

.blogs-posts-outer {
  height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevents scrolling on the entire page */
}

.blogs-posts-inner {
  height: 80vh; /* Adjust the height as needed */
  width: 100%;
  overflow-y: auto; /* Enables vertical scrolling */
  padding: 10px;
  box-sizing: border-box;
}

.blogs-posts-inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.latest-blogs {
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 0.2rem solid var(--secondary_color);
}

.latest-blogs-inner {
  height: 80vh;
  overflow-y: auto; /* Enables vertical scrolling for latest blogs section */
  padding: 10px;
  box-sizing: border-box;
}

.blog-post, .latest-post {
  border: 0.1rem solid #ccc;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 20px;
}

.blog-image-outer {
  text-align: center;
  margin-bottom: 15px;
}

.blog-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.blog-body {
  padding: 10px;
}

.blog-post-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.blog-author {
  font-weight: bold;
}

.blog-date {
  display: flex;
  align-items: center;
}

.blog-pen-icon {
  margin-right: 5px;
}

.blog-title {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.blog-content {
  margin-bottom: 10px;
}

.blog-more {
  color: #007bff;
  text-decoration: none;
}

.blog-social {
  display: flex;
  gap: 10px;
}

.blog-social {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.blog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Vertically aligns items */
  width: 100%;
  /* Ensures it takes the full width of its container */
}

.categories-list {
  list-style-type: none;
  padding: 0;
}

.blog-category {
  text-decoration: none;
  color: #007bff;
}

.blog-category:hover {
  text-decoration: underline;
}

.latest-blogs-cont {
  width: 2%;
  display: flex;
  flex-direction: column;
}
.latest-blogs {
  padding: 1rem;
  padding-right: 0.8rem;
  background: #fff;
  border-radius: 1rem;
  border: 0.2rem solid var(--secondary_color);
  height: calc(100% - 2.5rem);
}
.blogs .mob-post-search {
  width: 100%;
  display: none;
  justify-content: center;
  width: 100%;
  margin-bottom: 0.5rem;
}


@media (max-width: 576px) {
  .blogs-posts-inner-mob {
    gap: 10px;
    display: flex;
    height: 40vh;
    width: 100%;
    overflow-y: auto;
    padding: 10px;
    box-sizing: border-box;
}
.blogs-posts-inner-mob-vert {
  gap: 10px;
  display: flex;
  height: 40vh;
  width: 100%;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
  flex-direction: column;
  flex-wrap: nowrap;
}


  .blog-title {
    color: #444;
    font-weight: 600;
    margin-top: 0.8rem;
    line-height: 130%;
}
.blog-post-mob {
  border: 0.1rem solid #ccc;
  /* padding: 15px; */
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 20px;
}
}


.contents {
  display: flex;
  gap: 20px;
  /* margin-top: 100px; */
}

.header-container {
  max-width: 1830px;
  /* margin: auto; */
  padding: 20px;
  text-align: left;
  margin-top: 50px;
  /* padding-left: 25rem; */
}

/* Main title and subtitle */
/* .header-content h1 {
  font-family: "Times New Roman", Times, serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: #035189;
  margin-bottom: 0.5rem;
} */

.header-content h1 span {
  font-family: "Times New Roman", Times, serif;
    display: inline-block;
  font-size: 0.9rem;
  font-weight: normal;
  color: #5f6c87;
}

.header-content p {
  font-family: monospace;  
  color: #035189;
  margin: 10px 0 20px;
}

.subscription-line {
  display: flex;
  align-items: left;
  justify-content: left;
  gap: 15px;
  /* Adjust spacing between text and form */
}

.subscription-line p {
  /* margin: 0; */
  font-size: 1em;
  color: #555;
}

.subscribe-form {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.subscribe-form input {
  padding: 10px;
  font-size: 1em;
  margin-right: 5px;
}

.subscribe-form button {
  padding: 10px;
  background-color: #ff5a5f;
  color: #fff;
  border: none;
  cursor: pointer;
}

.main-article {
  flex: 2;
  /* padding: 20px; */
  /* padding-left: 20rem; */
  background-color: #f9f9f9;
  border-radius: 1px;
}

.main-article-image {
  height: 15rem;
  border-radius: 1px;
  padding-left: 15rem;
}

.main-article h2 {
  font-size: 1.5em;
  /* margin: 10px 0; */
    /* padding-left: 25rem; */
}

.main-article p {
  color: #555;
    /* padding-left: 25rem; */
}

.article-info {
  display: flex;
  /* justify-content: space-between; */
  color: gray;
    /* padding-left: 25rem; */
    margin-right: 50rem;
}

.sidebar {
  flex: 1;
  padding: 20px;
  border-left: 3px solid #ddd;
}

.sidebar h3 {
  font-size: 1.2em;
  margin-bottom: 15px;
  font-weight: bold;
}

.sidebar-article {
  display: flex;
  margin-bottom: 15px;
}

.sidebar-article img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 5px;
}

.feat-article-details h4 {
  font-size: 1em;
  margin: 0;
  /* font-weight: bold; */
}

.feat-article-details p {
  font-size: 0.9em;
  color: gray;
}

/* LatestArticles.css */
.latest-articles {
  padding: 20px;
  font-family: Arial, sans-serif;
    /* padding-left: 25rem; */
}

.latest-articles h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.articles-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.article-card {
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  max-width: 500px;
  width: 32%;
}

.latimage {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 15px;
}

.article-details {
  flex-grow: 1;
}

.article-title {
  font-size: 1em;
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

.article-title:hover {
  text-decoration: underline;
}

.article-meta {
  display: flex;
  gap: 10px;
  font-size: 0.85em;
  color: #666;
  margin-top: 5px;
}

.article-author,
.article-date {
  font-weight: normal;
}

/* AICategory.css */
.ai-category {
  /* padding: 20px; */
  font-family: Arial, sans-serif;
      /* padding-left: 25rem; */
}

.ai-category h2 {
  font-size: 1.5em;
  font-weight: bold;
  /* margin-bottom: 10px; */
  /* border-bottom: 2px solid #e63946; */
  display: inline-block;
  /* padding-bottom: 5px; */
}

.articles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
}

.article-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
}

.aiimage {
  /* width: 100%; */
  /* height: 120px; */
  object-fit: cover;
}

.article-details {
  padding: 10px;
}

.article-title {
  font-size: 1em;
  font-weight: bold;
  color: #333;
  text-decoration: none;
  display: block;
  margin-bottom: 8px;
}

.article-title:hover {
  text-decoration: underline;
}

.article-meta {
  display: flex;
  flex-direction: column;
  font-size: 0.85em;
  color: #666;
}

.article-author,
.article-date {
  font-weight: normal;
}


.card-grid {
  display: flex;
  gap: 15px;
  /* justify-content: center; */
  flex-wrap: wrap;
}

.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
  overflow: hidden;
  text-align: left;
}

.card-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.card-content {
  padding: 15px;
}

.card-title {
  font-size: 18px;
  margin: 0;
  margin-bottom: 8px;
}

.card-author,
.card-date {
  color: #777;
  font-size: 14px;
  margin: 0;
}

.slider-container {
  overflow: hidden;
  /* Hide overflow to clip the animation */
  width: 100%;
}

.slide-animation {
  /* animation: slideIn 2s ease-in-out; */
  /* Apply animation for each slide */   
    transition: opacity 1s ease-in-out;
    animation: slideInOut 4s ease-in-out;
}

@keyframes slideInOut {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  10% {
    opacity: 1;
    transform: translateX(0);
  }

  80% {
    opacity: 1;
    transform: translateX(0);
  }

  90% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.heading-dotted-machine::before {
    content: "...";
    position: absolute;
    bottom: -16px;
    left: 100%;
  }

  @keyframes l14 {
    0% {
      transform: translateX(0);
    }
    100% {
        left: calc(100% + 20px);
    }
  }

  .loader-ip::before {
    content: "";
    position: absolute;
    margin: 2px;
    width: 14px;
    top: 0;
    bottom: 0;
    left: -20px;
    border-radius: inherit;
    background: currentColor;
    box-shadow: -10px 0 12px 3px currentColor;
    clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%, -30px 50%);
    animation: l14 1s infinite linear;
    
}

.machine-ip{
    /* margin-top: 15px; */
    font-size: 13px;
    color: #777;
    font-weight: 600;
}

.machine-date {
    /* margin-top: 15px; */
    font-size: 13px;
    color: #777;
    font-weight: 500;
}

.loader-ip {
    width: 83px;
    height: 17px;
    border-radius: 40px;
    color: #035189;
    border: 2px solid;
    position: relative;
    overflow: hidden;
    margin-top: -16px;
    margin-right: -66px;
}

  .heading-dotted-machine {
    font-size: 34px;
    font-weight: 500;
    padding: 10px 0;
    border-bottom: 2px solid #154e7a;
    display: inline-block;
    position: relative;
    text-transform: capitalize;
    color: #545454 !important;
    margin-left: 9rem;
  }
    .machines .box .machine {
    padding: 20px;
}
.machine-config-badge {
    /* margin-top: 10px; */
    padding: 5px 10px;
    background-color: #aaa;
    border-radius: 5px;
    font-size: 13px;
}
  .machines .machine .machine-name {
    font-weight: 500;
    /* margin-top: 10px; */
}
  .machines .machine .machine-ip,   .machines .machine .machine-date {
    font-size: var(--font-size-xs);
}

.knobs {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.layer {
    width: 100%;
    background-color: #008000;
    transition: 0.3s ease all;
    z-index: 1;
}
.checkstyle.button.b2 {
    border-radius: 25px;
}

.button-10 .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.checkstyle.button {
    position: relative;
    width: 85px;
    height: 42px;
    margin: 0px auto 0 auto;
    overflow: hidden;
    float: left;
}
.button-11 .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

  .machines .box .machine {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
}

  .machines .machine .bg-image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
}

 .machine-name {
    font-weight: 500;
    /* margin-top: 10px; */
}
.theme-color-blue {
    color: #545454 !important;
}

.wallet-container{
    width: 100%;
    margin-top: 1.5rem;
    height: 100%;
    border: 0.15rem solid var(--primary_color);
    border-radius: 1rem;
    padding: 1rem;
  }

   .wallet-box {
    display: flex;
    height: 100%;
    overflow-y: scroll;
    padding-right: 1rem;
    justify-content: space-evenly;
}
/* Webkit-based browsers (Chrome, Safari) */
.wallet-box::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
  }
  
  .wallet-box::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background color of the scrollbar track */
    border-radius: 1rem;
  }
  
  .wallet-box::-webkit-scrollbar-thumb {
    background-color: var(--primary_color); /* Color of the scrollbar thumb */
    border-radius: 1rem;
    border: 3px solid #f1f1f1; /* Space around the thumb */
  }

  .iconimg{
    width: 60%;
    margin-left: 20%;
  }

  @media (max-width: 576px) {


    .heading-dotted-machine {
        font-size: 18px ;
        font-weight: 500;
        padding: 10px 0;
        border-bottom: 2px solid #154e7a;
        display: inline-block;
        position: relative;
        text-transform: capitalize;
        color: #154e7a;
        top: 3rem;
        margin-left: 10px;
        margin-bottom: 4rem;
    }

    .heading-dotted-machine::before {
        content: "...";
        position: absolute;
        bottom: -9px;
        left: 100%;
    }




  @keyframes l14 {
    0% {
      transform: translateX(0);
    }
    100% {
        left: calc(100% + 20px);
    }
  }

  .loader::before {
    content: "";
    position: absolute;
    margin: 2px;
    width: 14px;
    top: 0;
    bottom: 0;
    left: -20px;
    border-radius: inherit;
    background: currentColor;
    box-shadow: -10px 0 12px 3px currentColor;
    clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%, -30px 50%);
    animation: l14 1s infinite linear;
    
}

.machine-ip{
    /* margin-top: 15px; */
    font-size: 13px;
    color: #777;
    font-weight: 600;
}

.machine-date {
    /* margin-top: 15px; */
    font-size: 13px;
    color: #777;
    font-weight: 500;
}

.loader {
    width: 83px;
    height: 17px;
    border-radius: 40px;
    color: #035189;
    border: 2px solid;
    position: relative;
    overflow: hidden;
    margin-top: -16px;
    margin-right: -66px;
}



.machines .box .machine {
    padding: 20px;
}

.machine-config-badge {
    /* margin-top: 10px; */
    padding: 5px 10px;
    background-color: #aaa;
    border-radius: 5px;
    font-size: 13px;
}
  .machines .machine .machine-name {
    font-weight: 500;
    /* margin-top: 10px; */
}
  .machines .machine .machine-ip,   .machines .machine .machine-date {
    font-size: var(--font-size-xs);
}

.knobs {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.layer {
    width: 100%;
    background-color: #008000;
    transition: 0.3s ease all;
    z-index: 1;
}
.checkstyle.button.b2 {
    border-radius: 25px;
}

.button-10 .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.checkstyle.button {
    position: relative;
    width: 85px;
    height: 42px;
    margin: 0px auto 0 auto;
    overflow: hidden;
    float: left;
}
.button-11 .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

  .machines .box .machine {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
}

  .machines .machine .bg-image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
}

 .machine-name {
    font-weight: 500;
    /* margin-top: 10px; */
}
.theme-color-blue {
    color: #545454 !important;
}

.wallet-container {
    margin-left: 20px;
    width: 90%;
    margin-top: 1.5rem;
    height: 100%;
    border: 0.15rem solid var(--primary_color);
    border-radius: 1rem;
    padding: 1rem;
}

.wallet-box {
    margin-left: 30px;
    display: flex;
    height: 100%;
    overflow-y: scroll;
    padding-right: 1rem;
    justify-content: space-evenly;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}
/* Webkit-based browsers (Chrome, Safari) */
.wallet-box::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
  }
  
  .wallet-box::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background color of the scrollbar track */
    border-radius: 1rem;
  }
  
  .wallet-box::-webkit-scrollbar-thumb {
    background-color: var(--primary_color); /* Color of the scrollbar thumb */
    border-radius: 1rem;
    border: 3px solid #f1f1f1; /* Space around the thumb */
  }

  .iconimg{
    width: 60%;
    margin-left: 20%;
  }

  }


  /* HTML: <div class="loader"></div> */
.loader-vnc {
  width: 45px;
  height: 30px;
  background:
    linear-gradient(#e97730 0 0) 0 100%/100% 50%,
    linear-gradient(#e97730 0 0) 0 0   /calc(100%/3) 100%;
  background-repeat: no-repeat;
  position: relative;
  clip-path: inset(-100% 0 0 0);
  animation: l2-0 2s infinite steps(4);
}
.loader-vnc::before,
.loader-vnc::after {
  content: "";
  position: absolute;
  inset:-50% 0 50%;
  background:
    linear-gradient(rgb(128, 128, 128)0 0) 0 0      /calc(2*100%/3) 50%,
    linear-gradient(rgb(128, 128, 128)0 0) 100% 100%/calc(2*100%/3) 50%;
  background-repeat: no-repeat;
  animation: inherit;
  animation-name: l2-1;
}
.loader-vnc::after {
  inset:-100% 0 100%;
  background:
    linear-gradient(rgb(3, 81, 137) 0 0) 0    0/100%         50%,
    linear-gradient(rgb(3, 81, 137) 0 0) 100% 0/calc(100%/3) 100%;
  background-repeat: no-repeat; 
  animation-name: l2-2;
}
@keyframes l2-0{
  0%       {transform: translateY(-250%);clip-path: inset(100% 0 0 0)}
  25%,100% {transform: translateY(0);clip-path: inset(-100% 0 0 0)}
}
@keyframes l2-1{
  0% ,25%  {transform: translateY(-250%)}
  50%,100% {transform: translateY(0)}
}
@keyframes l2-2{
  0% ,50%  {transform: translateY(-250%)}
  75%,100% {transform: translateY(0)}
}

.create-new-machine{
  width: 10rem;
  margin-top: 2px;
  z-index: 9;
  position: relative;
  font-weight: 700;
  color: white;
  height: 55px;
  background-color: #e97730;
  outline: 4px solid #e97730;
  border: 4px solid #ffff;
  border-color: white;
  border-radius: 30px;
}


.loader-machine-make {    
  --r1: 154%;
  --r2: 68.5%;
  width: 70px;
  aspect-ratio: 1;
  border-radius: 50%; 
  background:
    radial-gradient(var(--r1) var(--r2) at top   ,#0000 79.5%,#e97730 80%),
    radial-gradient(var(--r1) var(--r2) at bottom,#e97730 79.5%,#0000 80%),
    radial-gradient(var(--r1) var(--r2) at top   ,#0000 79.5%,#e97730 80%),
    #ccc;
  background-size: 50.5% 220%;
  background-position: -100% 0%,0% 0%,100% 0%;
  background-repeat:no-repeat;
  animation: l9 2s infinite linear;
}
@keyframes l9 {
    33%  {background-position:    0% 33% ,100% 33% ,200% 33% }
    66%  {background-position: -100%  66%,0%   66% ,100% 66% }
    100% {background-position:    0% 100%,100% 100%,200% 100%}
}



/* HTML: <div class="loader"></div> */
.loader-delete {
  display: inline-grid;
  width: 30px;
  aspect-ratio: 1;
  animation: l11-0 3s linear infinite;
}
.loader-delete:before,
.loader-delete:after {
  content: "";
  grid-area: 1/1;
}
.loader-delete:before {
  clip-path: polygon(100% 50%,83.81% 59.06%,93.3% 75%,74.75% 74.75%,75% 93.3%,59.06% 83.81%,50% 100%,40.94% 83.81%,25% 93.3%,25.25% 74.75%,6.7% 75%,16.19% 59.06%,0% 50%,16.19% 40.94%,6.7% 25%,25.25% 25.25%,25% 6.7%,40.94% 16.19%,50% 0%,59.06% 16.19%,75% 6.7%,74.75% 25.25%,93.3% 25%,83.81% 40.94%);
  background: #07528b;
  transform: rotate(0turn) translate(-12.5%) rotate(0turn);
  animation: l11-1 2s linear infinite;
}
.loader-delete:after {
  margin: 12.5%;
  clip-path: polygon(100% 50%,78.19% 60.26%,88.3% 82.14%,65% 75.98%,58.68% 99.24%,44.79% 79.54%,25% 93.3%,27.02% 69.28%,3.02% 67.1%,20% 50%,3.02% 32.9%,27.02% 30.72%,25% 6.7%,44.79% 20.46%,58.68% 0.76%,65% 24.02%,88.3% 17.86%,78.19% 39.74%);
  background: #e97730;
}
@keyframes l11-0 {to{rotate: 1turn;}}
@keyframes l11-1 {to{transform: rotate(-1turn) translate(-12.5%) rotate(.75turn);}}


.delete-popup-div-1 {
  top: 7%;
  left: 4%;
  position: absolute;
  z-index: 9;
  width: 90%;
  backdrop-filter: blur(5px);
  height: 20rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border: 2px solid #e97730;
}

.delete-popup-yes-btn-1 {
  width: 8rem;
      margin-top: 2px;
      z-index: 9;
      position: relative;
      font-weight: 700;
      color: white;
      height: 55px;
      background-color: #aaa;
      outline: 4px solid #aaa;
      border: 4px solid #ffff;
      border-color: white;
      border-radius: 30px;
}

.delete-popup-no-btn-1 {
  width: 8rem;
  margin-top: 2px;
  z-index: 9;
  position: relative;
  font-weight: 700;
  color: white;
  height: 55px;
  background-color: #e97730;
  outline: 4px solid #e97730;
  border: 4px solid #ffff;
  border-color: white;
  border-radius: 30px;
}

.input-container-1 {
  margin-left: 55%;
  position: relative;
  border: 2px solid #035189;
  width: 10rem;
  margin-top: -50px;
  height: 45px;
}

.machine-titles {
  display: flex;
    justify-content: center;
    align-items: center;
  width: max-content;
  font-size: 20px;
  padding: 6px 15px;
  position: relative;
  font-size: 15px;
  min-width: 110px;
  text-align: center;
  border: 4px solid #fff;
  border-radius: 50px;
  padding: 2px 10px;
  color: #fff;
  font-weight: 500;
  outline: 2px solid #e97730;
}

.machine-titles::before {
  content: " ";
  position: absolute;
  z-index: -1;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  border-radius: 18px;
  /* border: 5px solid #e97730; */
}

.machine-titles::after {
  position: absolute;
  content: "";
  background-color: #fff;
  width: 15px;
  height: 5px;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
}

.box .hover-text {
  display: none;
  /* Initially hidden */
}

.box img:hover+.hover-text {
  display: block;
  /* Show when hovering over the image */
}


.profile-image-container  {
    display: grid;
    margin: auto;
    width: 90px;
    height: 90px;
    border: 1px solid #035189;
    text-align: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
}




.profile-image {
    /* margin: 5px 1px 3px 5px; */
    height: 90%;
    width: 90%;
    border: 2px solid #035189;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
}



.profile-badge {
    max-height: 40px;
    display: block;
    justify-self: center;
    margin-left: -4rem;
    max-width: 15rem;
    word-wrap: break-word;
    background-color: #035189;
    padding: 2px 10px;
    border-radius: 15px;
    color: #ffffff;
    font-size: 21px;
    text-align: center;
    margin-top: 10px;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    /* maxWidth: "130px", */
            /* textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: "inline-block", */
}

.profile-user-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #f47c20;
    border-radius: 25px;
    padding: 5px;
    height: 41px;
    width: 10rem;
    margin-left: 21%;
    color: #f47c20;
}

li:first-child {
    margin-top: 30px;
}

li {
    margin-top:18px;
}

.profile-menuitem {
    font-size: 24px;
    border-radius: 50px;
    line-height: 100%;
    border: 1px solid #e97730;
    
    padding: 2px 10px;
    border-radius: 15px;
    color: #e97730;
}

.main-view-popup {
    display: grid;
    margin-top: 65px;
    margin-left: 80%;
    position: fixed;
    width: 25rem;
    height: 22.5rem;
    z-index: 2147483647;
    border-radius: 24px;
    transition: all 0.3s ease 0s;
    background-image: "/admin/images/admin/02-vm/gray-box-bg.svg";
    background-size: cover;
    background-position: center center;
    justify-items: normal;
}


@media (max-width: 576px) {

    .main-view-popup {
        display: grid;
        margin-top: 90px;
        margin-left: 40%;
        position: fixed;
        width: 15rem;
        height: 18rem;
        z-index: 2147483647;
        border-radius: 24px;
        transition: all 0.3s ease 0s;
        /* background-image: url(/admin/images/admin/02-vm/gray-box-bg.svg); */
        background-size: cover;
        background-position: center center;
    }
          .profile-badge {
              max-height: 50px;
              margin-left: -25px;
              display: block;
              word-wrap: break-word;
              justify-self: center;
              max-width: 9rem;
              background-color: #035189;
              padding: 2px 10px;
              border-radius: 15px;
              color: #ffffff;
              font-size: 15px;
              text-align: center;
              margin-top: 10px;
              position: absolute;
          }

    .profile-menuitem {
        font-size: 16px;
        border-radius: 50px;
        line-height: 100%;
        border: 1px solid #e97730;
        
        padding: 2px 10px;
        border-radius: 15px;
        color: #e97730;
    }
}


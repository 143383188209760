.util-cont {
    margin-top: 9rem;
    margin-left: 20%;
    width: 100%;
    display: flex;
    height: 100rem;
    padding-right: 0rem;
    gap: 10%;
}

.title-cont {
    width: max-content;
    padding-left: 10px;
    padding-right: 10px;
    height: 35px;
    margin-top: -1px;
    margin-left: -35px;
    background-color: #e97730;
    border: 3px solid rgb(255, 255, 255);
    border-radius: 25px;
    outline: rgb(233, 119, 48) solid 2px;
}

.title-style {
    color: white;
    background-color: rgb(233, 119, 48);
    border-radius: 15px;
    height: 25px;
    font-size: 24px;
    font-weight: 700;
}
@media (max-width: 576px) {
    .util-cont{
        margin-left: 0;
        gap: 23%;
        margin-top: 9rem;
        width: 100%;
        display: flex;
        /* height: 100rem; */
        /* padding-right: 0rem; */
        flex-wrap: nowrap;
        align-content: stretch;
        /* justify-content: space-around; */
        flex-direction: column;
        /* margin-top: 9rem;
        width: 100%;
        display: flex;
        height: 100rem;
        
        padding-right: 0rem;
        justify-content: space-evenly;
         */
    }
}

.white-div-uti{
    position: relative;
    z-index: 9999;
    left: 50px;
    top: -37px;
    height: 5px;
    width: 15px;
    background-color: white;    
}
/* NotServed.css */
.not-served-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120vh;
    background-color: #f8f8f8;
    font-family: Arial, sans-serif;
}

.not-served-content {
    text-align: center;
    height: 300px;
    max-width: 800px;
    padding: 3rem;
    background: white;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
}

.not-served-content h1 {
    font-size: 3rem;
    color: #e67225;
    margin-bottom: 1rem;
}

.not-served-content p {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1.5rem;
}

.not-served-content button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    background-color: #07528b;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.not-served-content button:hover {
    background-color: #07528b;
}